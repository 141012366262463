import Victor from "victor";
//import $ from 'jquery';
import { ALL_INDEXES, vertexOfOriginRect } from "./rect";
//import { debugPoint } from "../utils/debug";

export const sizeOfRectVertices = (topLeft, bottomRight) => {
  const { x: width, y: height } = bottomRight.clone().subtract(topLeft);
  return { width, height };
};

export const verticesOfRect = (fix, diagonal) => {
  const vertices = [];
  vertices[3] = new Victor(
    Math.min(fix.x, diagonal.x),
    Math.min(fix.y, diagonal.y)
  );
  vertices[1] = new Victor(
    Math.max(fix.x, diagonal.x),
    Math.max(fix.y, diagonal.y)
  );
  const size = sizeOfRectVertices(vertices[3], vertices[1]);
  const sizeVictor = new Victor(size.width, size.height);
  vertices[0] = vertices[3].clone().addX(sizeVictor);
  vertices[2] = vertices[3].clone().addY(sizeVictor);

  return {
    size,
    vertices
  };
};

export const getBoundingBox = (left, top, width, height, rotation) => {
  let newTop = null;
  let newLeft = null;
  let newRight = null;
  let newBottom = null;
  let center = new Victor(parseFloat(left) + parseFloat(width / 2), parseFloat(top) + parseFloat(height / 2));
  let halfSize = new Victor(width / 2, height / 2);
  ALL_INDEXES.forEach((i) => {
      let vertex = vertexOfOriginRect(i, width, height);
      vertex.subtract(halfSize).rotateDeg(rotation).add(center);
      if (newTop === null || vertex.y < newTop) {
        newTop = vertex.y;
      }
      if (newLeft === null || vertex.x < newLeft) {
        newLeft = vertex.x;
      }
      if (newRight === null || vertex.x > newRight) {
        newRight = vertex.x;
      }
      if (newBottom === null || vertex.y > newBottom) {
        newBottom = vertex.y;
      }
  })
  return {
    x: newLeft,
    y: newTop,
    width: newRight - newLeft,
    height: newBottom - newTop,
  }
}
