import axios from 'axios'
//import i18n from '@/i18n'

const request = () => {

    // create an axios instance
    const service = axios.create({
        //baseUrl, // url = base url + request url
        // withCredentials: true, // send cookies when cross-domain requests
        timeout: 5000 // request timeout
    })

    // request interceptor
    service.interceptors.request.use(
        config => {
            // do something before request is sent
            /*if (i18n.locale) {
              config.headers['X-localization'] = i18n.locale
            }*/
            config.headers['X-Requested-With'] = 'XMLHttpRequest';

            const meta = document.querySelector('meta[name="csrf-token"]')
            if(meta) {
                config.headers['X-CSRF-TOKEN'] = meta.getAttribute('content');
            }
            return config;
        },
        error => {
            // do something with request error
            console.log(error) // for debug
            return Promise.reject(error)
        }
    )

    // response interceptor
    service.interceptors.response.use(
        /**
         * If you want to get http information such as headers or status
         * Please return  response => response
         */

        /**
         * Determine the request status by custom code
         * Here is just an example
         * You can also judge the status by HTTP Status Code
         */
        response => {
            const res = response.data

            // if the custom code is not 20000, it is judged as an error.
            // if (res.code !== 20000) {
            // Check with status code instead
            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                alert(res.message || 'Error')
                /*Message({
                  message: res.message || 'Error',
                  type: 'error',
                  duration: 5 * 1000
                })*/

                // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
                // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                if (response.status === 401 && process.env.NODE_ENV=='production') {
                    // to re-login
                    alert('You have been logged out, you can cancel to stay on this page, or log in again');
                    /*MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
                      confirmButtonText: 'Re-Login',
                      cancelButtonText: 'Cancel',
                      type: 'warning'
                    }).then(() => {
                      store.dispatch('user/resetToken').then(() => {
                        location.reload()
                      })
                    })*/
                    location.reload();
                }
                return Promise.reject(new Error(res.message || 'Error'))
            } else {
                return res
            }
        },
        (error) => {
            // Message({
            //   message: message,
            //   type: 'error',
            //   duration: 5 * 1000
            // })
            let ret = {}
            if(!error.response) {
                return Promise.reject(error)
            }
            if (error.response.data) {
                ret = error.response.data
            }
            if (error.response.status === 401 && ['production', 'staging'].indexOf(process.env.NODE_ENV)!==-1/* && !ret.message*/) {
                location.reload();
            }
            if (error.response.status === 403 && !ret.message) {
                if (error.response.config.responseType === 'blob' && error.response.headers['content-type'] === 'application/json') {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader()
                        reader.onload = () => {
                            error.response.data = JSON.parse(reader.result)
                            resolve(Promise.reject(error.response.data))
                        }

                        reader.onerror = () => {
                            reject(error)
                        }

                        reader.readAsText(error.response.data)
                    })
                }
                ret.message = 'Unauthorized'
            }
            if (error.response.status === 404 && !ret.message) {
                if (error.response.config.responseType === 'blob' && error.response.headers['content-type'] === 'application/json') {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader()
                        reader.onload = () => {
                            error.response.data = JSON.parse(reader.result)
                            resolve(Promise.reject(error.response.data))
                        }

                        reader.onerror = () => {
                            reject(error)
                        }

                        reader.readAsText(error.response.data)
                    })
                }
                ret.message = 'Not Found'
            }

            return Promise.reject(ret)
        }
    )

    return service
}

export default request()

