/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import 'alpine-magic-helpers/dist/component';
import 'alpinejs';
require('./bootstrap');


require('moment');

import React from 'react'

//import { InertiaForm } from 'laravel-jetstream';
//import PortalVue from 'portal-vue';
import { InertiaApp } from '@inertiajs/inertia-react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import {applyMiddleware, createStore, compose} from "redux";
import rootReducer from "@/reducers";
import rootSaga from "@/sagas";

import { composeWithDevTools } from 'redux-devtools-extension/logOnly';
import createSagaMiddleware from "redux-saga";
//import * as serviceWorker from './serviceWorker';
import Locale from "@/Components/Locale";

if (`${process.env.NODE_ENV}` !== "production") {
    window.React = React;
}

const composeEnhancers = composeWithDevTools({
    // options like actionSanitizer, stateSanitizer
    trace: true, traceLimit: 25
});
const sagaMiddleware = createSagaMiddleware()
let enhancers = composeEnhancers(
        applyMiddleware(sagaMiddleware),
        // other store enhancers if any
    );
if(process.env.MIX_SENTRY_JPBS_DSN && `${process.env.MIX_SENTRY_JPBS_DSN}`!=='undefined' && `${process.env.MIX_SENTRY_JPBS_DSN}`!=='') {
    let Sentry = require("@sentry/react");
    //let {BrowserTracing} = require("@sentry/browser");
    let {CaptureConsole: CaptureConsoleIntegration, ExtraErrorData} = require("@sentry/integrations");

    let logs = ['info', 'warn', 'error']; // , 'debug'
    if (`${process.env.NODE_ENV}` === "production") {
        logs = ['warn', 'error', 'debug'];
    }
    Sentry.init({
        //release: 'joindesignapp'+`${process.env.MIX_BRANCHNAME}`,
        environment: `${process.env.NODE_ENV}`,
        dsn: `${process.env.MIX_SENTRY_JPBS_DSN}`,
        ignoreErrors: [
            /extension:\//
        ],
        integrations: [
            new Sentry.BrowserTracing(),
            new CaptureConsoleIntegration(
                {
                    // array of methods that should be captured
                    // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
                    levels: logs
                }
            ),
            new ExtraErrorData({
                // Limit of how deep the object serializer should go. Anything deeper than limit will
                // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
                // a primitive value. Defaults to 3.
                depth: 5,
            })
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.5,
    });
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
        // Optionally pass options listed below
    });
    enhancers = composeEnhancers(
        applyMiddleware(sagaMiddleware),
        // other store enhancers if any
        sentryReduxEnhancer
    );
}

const store = createStore(rootReducer,
    enhancers
);
sagaMiddleware.run(rootSaga)

const app = document.getElementById('app')

if(app) {
    render(
        <Provider store={store}>
            <Locale>
                <InertiaApp
                    initialPage={JSON.parse(app.dataset.page)}
                    resolveComponent={name =>
                        import(`./Pages/${name}`).then(module => module.default)
                    }
                /></Locale>
        </Provider>,
        app
    )

}

/*import Vue from 'vue';

Vue.mixin({ methods: { route } });
Vue.use(InertiaApp);
Vue.use(InertiaForm);
Vue.use(PortalVue);

/!**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *!/

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/!**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 *!/

new Vue({
    render: (h) =>
        h(InertiaApp, {
            props: {
                initialPage: JSON.parse(app.dataset.page),
                resolveComponent: (name) => require(`./Pages/${name}`).default,
            },
        }),
}).$mount(app);*/

//serviceWorker.unregister();
