import dFHaiBao_icon from './fontStyleImg/DFHaiBao.svg';
import arial_icon from './fontStyleImg/Arial-01.svg';

export default
    {
      "engFonts": [
            {value: 'Arial', label: 'Arial' , img: arial_icon, url: 'fonts/Arial.ttf'}
        ],
        'otherFonts': [
            {value: 'DFHaiBao', label: 'DFHaiBao' , img: dFHaiBao_icon, url: 'fonts/DFHaiBao.ttf'}
        ]
    }
