import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import Cantonese from '../../../lang/zh.json';
import English from '../../../lang/en.json';
export const Context = React.createContext();
const local = navigator.language.split('-')[0];

let lang;
if (local==="en") {
    lang = Object.fromEntries(Object.entries(English).filter(([key]) => key.includes('.')));
} else {
    lang = Object.fromEntries(Object.entries(Cantonese).filter(([key]) => key.includes('.')));
}
/**
 * https://lokalise.com/blog/react-i18n-intl/#Declaring_Messages
 * description is optional
 * id and defaultMessage are mandatory
 */


const Locale = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    // eslint-disable-next-line
    function changeLanguage(e) {
        selectLanguage(e.target.value);
    }
    function selectLanguage(newLocale) {
        setLocale(newLocale);
        if (newLocale === 'en') {
            setMessages(English);
        } else {
            setMessages(Cantonese);
        }
    }
    return (
        <Context.Provider value = {{locale, selectLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}
export default Locale;
