//import { delay } from 'redux-saga'
import { put, takeEvery, all, select, takeLatest } from 'redux-saga/effects'
import { makeid } from '../utils/general'
import request from '@/utils/request'
import font_libraries from "@/data/fonts";

const MOCK_NETWORK_DELAY = 1000;

function* saveProject(action) {
  yield put({ type: 'SET_PROJECT_SAVING', isSaving: true })
  let callback = action.callback;

  let success = false;
  const args = {};
  if(action.team) {
      args.team = action.team;
  }


  let params = {
    url: action.saveId?route(action.route, {design: action.saveId}):route(action.route, {product:action.product_id, ...args}),
    method: action.saveId?'PATCH':'POST',
    data: {...action, project_name:action.projectName, code:action.saveId}
  };
  if(action.saveId)
  {
    params.timeout = 0;
  }
  yield request(params).then(res => {
      success = res;
    }).catch(e => {alert(e.message); action.error&&action.error(e)})


  yield put({ type: 'SET_PROJECT_SAVING', isSaving: false })
  if (success) {
      yield put({ type: 'SET_SAVE_ID', saveId: success.code });
      callback && callback(success);
  }
}

function* getFonts(){
    let params = {
        url: '/assets/fonts.json'
    };
    let fonts = {};
    let prod_fonts = false;

    if(process.env.NODE_ENV!='production') {
        yield request({...params, mode: 'no-cors', baseURL:'https://business.joinprint.com.hk', timeout: 2200}).then(res => {
            prod_fonts = {...res};
        }).catch((err) => {
            console.log(err);
            prod_fonts = {otherFonts:[], engFonts: []};
        })
    }

    yield request(params).then(res => {
        fonts = {...res};
    })
    if(process.env.NODE_ENV!='production') {
        if(Object.keys(fonts).length && prod_fonts){
            let index = -1;
            if(fonts.otherFonts)
            {
                prod_fonts.otherFonts = prod_fonts.otherFonts.map(font =>
                {
                    if(-1 !== (index = fonts.otherFonts.findIndex(nfont => font.label==nfont.label))) {
                        font = fonts.otherFonts.splice(index, 1)[0];
                    }
                    return font;
                }).concat(fonts.otherFonts);
            }

            if(fonts.engFonts)
            {
                prod_fonts.engFonts = prod_fonts.engFonts.map(font =>
                {
                    if(-1 !== (index = fonts.engFonts.findIndex(nfont => font.label==nfont.label))) {
                        font = fonts.engFonts.splice(index, 1)[0];
                    }
                    return font;
                }).concat(fonts.engFonts)
            }
            yield put({ type: 'SET_FONTS', fonts: prod_fonts });
        }
    }
    else if(Object.keys(fonts).length)
    {
        yield put({ type: 'SET_FONTS', fonts });
    }
}

function* saveProductOption(action) {
  yield put({ type: 'SET_PROJECT_SAVING', isSaving: true })
  let success = false;

  let params = {
    url: route('design.update', {design: action.saveId}),
    method: 'PATCH',
    data: {...action, code:action.saveId}
  };
  if(action.saveId)
  {
    params.timeout = 0;
  }
  yield request(params).then(res => {
      success = res;
    }).catch(e => {alert(e.message); action.error&&action.error(e)})


  yield put({ type: 'SET_PROJECT_SAVING', isSaving: false });
    if (success) {
        action.callback && action.callback(success);
    }
}


function* checkUserVerifyStatus(data){

  var verifyStatus,verifyNickname, verifyEmail = null;
  yield fetch(`${process.env.REACT_APP_API_HOST}/design/auth/` + code, {
    method : "GET",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    }
  })
  .then(function(response) {
      if(response.status==403) {
          location.reload();
      }
    return response.json();
  })
  .then(function(myJson) {
    if (myJson.status == 'Y') {
      verifyStatus=myJson.verify;
      verifyNickname = myJson.verify_nickname;
      verifyEmail = myJson.verify_email;
    } else {
      verifyStatus='0';
    }
  }).catch(e => {
    console.log(e);
    return e;
  });
  yield put({ type: 'SET_VERFIFY_STATUS', verifyStatus, verifyNickname, verifyEmail});
  data.callback("done");

}

function* checkUserSession(){
  var authStatus = null;
  var verifyStatus = null;

  yield fetch(`${process.env.REACT_APP_API_HOST}/design/auth/` + code, {
    method : "GET",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    }
  })
  .then(function(response) {
      if(response.status==403) {
          location.reload();
      }else if(response.status==503) {
          response.json().then(error => {
              let message = '';
              if (error.errors) {
                  if (typeof error.errors === 'object') {
                      message = Object.values(error.errors).join('\n');
                  }
              }
              else if (error.message) {
                  message = error.message;
              }
              if (!message) {
                  message = 'Unknown Error';
              }
              alert(message);
          })
      }
      return response.json();
  })
  .then(function(myJson) {
    if (myJson.status == 'Y') {
      authStatus = 'member';
      verifyStatus=myJson.verify;
    } else {
      authStatus = 'guest';
      verifyStatus='0';
    }
    //console.log("1: "+authStatus);
    //console.log("3: "+verifyStatus);
  }).catch(e => {
    console.log(e);
    return e;
  });
  //console.log("2: "+authStatus);
  yield put({ type: 'SET_AUTH_STATUS', authStatus });
  yield put({ type: 'SET_VERFIFY_STATUS', verifyStatus });
}

function* promptLogin(action){
  let callback = action.callback;

  let popupWindow = null;

  let checkWindowClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
          popupWindow = null;
          clearInterval(checkWindowClosed);
          callback("Failed");
      }
  }, 1000);
  window.addEventListener('message', (event) => {
      if (event.origin.indexOf(`${process.env.REACT_APP_API_HOST}`) !== -1) {
        //console.log('sagas: '+event.data);
          if (event.data.action == 'authCallback') {
              //console.log("Auth callback B: " + event.data.data.status)
              if (popupWindow) {
                  popupWindow.close();
                  popupWindow = null;
                  clearInterval(checkWindowClosed);
              }
              callback(event.data.data.status);
              if (event.data.data.status == 200) {
                put({ type: 'SET_AUTH_STATUS', authStatus: 'member' })
              }
          }
      }
  }, {once: true})
  popupWindow = window.open(`${process.env.REACT_APP_API_HOST}` + '/design/login/'+code, 'Login', 'height=600,width=700,resizable=yes,toolbar=no,menubar=no,location=no,status=no');
}

function* promptSlackLogin(action){
  let callback = action.callback;

  let popupWindow = null;

  let checkWindowClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
           popupWindow = null;
          clearInterval(checkWindowClosed);
          if (!code) {
              callback("Failed");
          } else {
            callback('OK');
          }
      }
  }, 1000);
  popupWindow = window.open('https://slack.com/oauth/authorize?client_id=576304338454.575941323991&scope=users.profile:read,files:write:user,channels:read,groups:read','Login', 'height=600,width=700,resizable=yes,toolbar=no,menubar=no,location=no,status=no');
}

/*function* loadProject(data){
  var access_level = -1;
  var username = 0;
  var permission = "";
  var project_target="";
  var product_name = "";
  var product_id = "";
  var recentlyUsedImages = [];
  var recentlyUsedPhotos = [];
  var hidePageOption = false;
  var max_page = null;
  var min_page = null;

  var is_customise_further=false;
  var is_instant_preview=false;
  var team = [];

  var response_json = {};

  yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/get`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
      if(myJson.status!=="E"){
        access_level = myJson.access_level;
        username = myJson.username;
        permission = myJson.permission;
        payment_term= myJson.payment_term;
        projectOptions=myJson.projectOptions;
        var fonts = [], colors = [], brand_token_list= [];
        var has_watermark=true;
        var is_printable =false;

        recentlyUsedImages = myJson.recentlyUsedImages ? myJson.recentlyUsedImages : [];
        recentlyUsedPhotos = myJson.recentlyUsedPhotos ? myJson.recentlyUsedPhotos : [];

        if (myJson.is_printable==1){
          is_printable=true;
        }
        if (myJson.is_customise_further==1){
          is_customise_further=true;
        }
        if (myJson.is_instant_preview==1){
          is_instant_preview=true;
        }
        let project_name = myJson.items[0].project_name;
          var items = myJson.items;
          if(items.length>0){
          var item = items[0];
          if(item !== null && item !== ""){
            if (item.max_page) {
              max_page=item.max_page;
            }
            if (item.min_page) {
              min_page=item.min_page;
            }
            product_name = item.product_name;
            product_id = item.product_id;
            var callback_url = "";
            if(item.callback != null){
              callback_url = item.callback;
            }
            var partnership_link = "";
            if(item.partnership_link != null){
              partnership_link = item.partnership_link;
            }
            var select_from_team_id=0;
            if(item.select_from_team_id != null){
              select_from_team_id = item.select_from_team_id;
            }
            if(item.json !== null  && item.json !== ""){
                response_json = item.json.pages;
                data.callback(
                  response_json, access_level, project_name, username, permission, product_name, custom_fonts, custom_colors,
                  brand_kit, load_fonts_project,has_watermark,is_printable, callback_url, partnership_link, item.main_url, item.save_url, recentlyUsedImages, /!*recentlyUsedPhotos,*!/project_target,payment_term,select_from_team_id, team, item.partnership_param );
            }else{
              data.callback(
                null, access_level, project_name, username, permission, product_name, custom_fonts, custom_colors,
                brand_kit, load_fonts_project,has_watermark,is_printable, item.callback, partnership_link, "", "", recentlyUsedImages, /!*recentlyUsedPhotos,*!/project_target,payment_term,select_from_team_id, team, item.partnership_param );
            }
          }
        }
      }

  }).catch(e => {
    console.log(e);
    return e;
  });

  yield put({ type: 'SET_PROJECT_CODE', projectCode: code })
  yield put({ type: 'SET_PROJECT_PRODUCT_ID', productId: product_id })
  yield put({ type: 'SET_CUSTOMISE_PREVIEW', is_customise_further: is_customise_further, is_instant_preview: is_instant_preview })

  /!*if (currentProjectOption) {
    for (var key in currentProjectOption) {
      var value = currentProjectOption[key];
      if (key=="print_design"){
        yield put({ type: 'SET_PRINT_DESIGN_OPTION', option: key, variation: value })
      } else{
        yield put({ type: 'SET_CURRENT_PROJECT_OPTION', option: key, variation: value })
      }
    }
  } else if (projectOptions && projectOptions.options && projectOptions.options.length) {
    for (var i in projectOptions.options) {
      let option = projectOptions.options[i];
      yield put({ type: 'SET_CURRENT_PROJECT_OPTION', option: option.name, variation: option.variations[0].name })
    }
  }*!/

}*/

/*function loadPrintOnly(data, callback){

  let response_json = {};

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/get`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : data.code
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {

      if(myJson.status!=="E"){
          var items = myJson.items;
              if(items.length>0){
              var item = items[0];
              if(item !== null && item !== ""){
                  if(item.json !== null  && item.json !== ""){
                      response_json = item.json.pages;
                      data.callback(response_json);
                  }
              }
          }
      }else{
          console.log("Fail to get project");
          data.callback(null);
          //self.createProject();
      }

  }).catch(e => {
    console.log(e);
    return e;
  });


}*/

function* exportProject(data){

  var param = data.param;

  yield put({ type: 'SET_IS_DOWNLOADING_OUTFILE', isDownloadingOutfile: true })
  yield put({ type: 'CLEAR_ACTIVE_ELEMENT' })
  const state = yield select();
  var saveProjectData = {};
  saveProjectData.pages = JSON.parse(JSON.stringify(state.pages));
  //yield saveProject({ type: 'SAVE_PROJECT', data: saveProjectData, saveId: state.currentSaveId, projectName: state.project_name, permission: state.permission});
  var exportAction="";
  if (param.action=="print"){ exportAction ="?exportAction=print";}
  yield fetch(`${process.env.REACT_APP_API_HOST}/design/export/`+param.width+'x'+param.height+'/'+param.code+'/'+param.token+'/'+param.type+exportAction, {
    method : "GET",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
      data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

  yield put({ type: 'SET_IS_DOWNLOADING_OUTFILE', isDownloadingOutfile: false })
}

function* exportProjectV2(data){

  var param = data.param;

  yield put({ type: 'SET_IS_DOWNLOADING_OUTFILE', isDownloadingOutfile: true })
  yield put({ type: 'CLEAR_ACTIVE_ELEMENT' })
  const state = yield select();
  var saveProjectData = {};
  saveProjectData.pages = JSON.parse(JSON.stringify(state.pages));

  var exportAction="";
  if (param.action=="print"){ exportAction ="?exportAction=print";}
  yield fetch(`${process.env.REACT_APP_API_HOST}/design/export/v2`+'/'+param.code+'/'+param.type+exportAction, {
    method : "GET",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
      data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

  yield put({ type: 'SET_IS_DOWNLOADING_OUTFILE', isDownloadingOutfile: false })
}


function getRecentlyUsedImages(data) {
  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/getRecentlyUsedImages`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "username":data.user_id,
      "category_id": data.category_id,
      "offset" : data.offset1,
      "limit": data.limit1
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });
}

function saveElementId(data){
  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/saveElementId`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "username":data.user_id,
      "elementId":data.element_id,
      "photos_element_ids": data.photo_element_ids
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });
}


function getDesignCategory(data){

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/getDesignCategory`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "image_type":data.category_id,
      "type":data.element_type,
      "offset":data.offset,
      "limit":data.limit,
      "brand_token_list" : data.brand_token,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function* saveDesignerTemplate(data){

  yield put({ type: 'SET_IS_DOWNLOADING_OUTFILE', isDownloadingOutfile: true });

  yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/saveDesignerTemplate`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code": data.code,
      "page": data.page,
      "type": data.saveType,
      "width" : data.width,
      "height": data.height,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

  yield put({ type: 'SET_IS_DOWNLOADING_OUTFILE', isDownloadingOutfile: false });
}

function bindProject(data){
  //var url = "http://127.0.0.1:6999/ajax/design/bind/"+code+"/"+api_token;
  var url = `${process.env.REACT_APP_API_HOST}/ajax/design/bind/`+code;
  //console.log(url);
  data.callback(url);

}

function savePreview(data){

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/savePreview`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "size" : data.width+"x"+data.height,
      "code" : code,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    console.log(myJson);
    data.callback();
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function setProjectShare(data){

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/setProjectShare`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
      "email" : data.email,
      "permission" : data.permission,
      "scope" : data.scope,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function setProjectShareTeam(data){

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/setProjectShare`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
      "team_id" : data.team_id,
      "permission" : data.permission,
      "scope" : data.scope,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function getProjectShare(data){

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/getProjectShare`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function getFolderList(data){

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/get-folders`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function setProjectFolder(data){

  var folder_id = data.folder_id;

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/set-project-folder`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
      "folder_id" : folder_id,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function setMyFavourite(data){

  var element_id = data.element_id;
  var star = data.star;
  //alert("setMyFavourite: "+element_id+", "+star);

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/set-my-favourite`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "element_id" : element_id,
      "star" : star,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    //data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function checkSocialMediaToken(data){
  var media_id = data.media_id;
  var action = "check"
  if (media_id) {
    action = "add"
  }

    var url = `${process.env.REACT_APP_API_HOST}/ajax/design/getSocialMediaToken`

  fetch(url, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "type": data.media_type,
      "social_media_id" : media_id,
      "action" : action
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function slackUploadFile(data){

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/slack_upload_file`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
     "code" : code,
      "token" : data.token,
      "text":  data.text,
      "channels": data.channels,
      "title": data.file_title
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(JSON.parse(myJson));
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function getCustomFonts(data){
  var username = data.username;

  fetch(`${process.env.REACT_APP_API_HOST}/fonts/get/` + username, {
    method : "GET",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    }
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

/*function* createOrder(action) {
  const state = yield select();
  var clientToken = null;
  var createdOrder = null;
  if (state.checkout.isCreatingOrder) return;
  yield put({ type: 'SET_IS_CREATING_ORDER', isLoading: true })

  var product = state.productList ? state.productList.find((product) => product.width == (state.pages.length ? state.pages[0].width : 0) && product.height == (state.pages.length ? state.pages[0].height : 0)) : null;
  if (!product) {
    product = state.productList[0];
  }
  if (!product) {
    return;
  }
  let orderDetails = {};
  orderDetails.productId = product.id;
  if (state.checkout.currencyOption.name) {
    orderDetails.currency = state.checkout.currencyOption.name;
  }
  orderDetails.variations = {};
  if (state.checkout.variations) {
    Object.keys(state.checkout.variations).forEach((key) => {
      orderDetails.variations[key] = state.checkout.variations[key].value;
    })
  }
  if (state.checkout.shippingMethod) {
    orderDetails.shippingMethod = state.checkout.shippingMethod.value;
  } else {
    orderDetails.shippingMethod = 'standard';
  }

  orderDetails.address = state.checkout.address;
  orderDetails.printFile = state.checkout.printFile;
  //orderDetails.pageCount = orderDetails.variations.printing_side == 'double' ? Math.ceil(state.pages.length / 2) : state.pages.length;
  let hasCommonBack = false;
  state.pages.forEach((page) => {
    if (page.commonBack) {
      hasCommonBack = true;
    }
  })

  orderDetails.pageCount = (hasCommonBack && orderDetails.variations.printing_side == 'double') ? (state.pages.length - 1) : (orderDetails.variations.printing_side == 'double' ? Math.ceil(state.pages.length / 2) : state.pages.length);

  orderDetails.projectCode = code;
  if (state.checkout.priceOption) {
    orderDetails.copy = state.checkout.priceOption.value;
  }

  yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/print/payment`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "payment_amount" : action.paymentAmount,
      "payment_method_nonce" : action.paymentNonce,
      "payment_term" : action.payment_term,
      "select_from_team_id" : action.select_from_team_id,
      "order_details" : orderDetails
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(transactiondata) {
    //console.log(transactiondata);
    //console.log(transactiondata.status);
    //console.log(transactiondata.msg);
    if (transactiondata.status=="success"){
      createOrder=transactiondata.ordercode;
      //console.log(createOrder);
      if (action.callback) {
        action.callback();
        alert('payment success! A confirmation email has been sent to your email '+transactiondata.email);
        if (transactiondata.team_token!=""){
          window.location = `${process.env.REACT_APP_API_HOST}/team/info/${transactiondata.team_token}?tab=team_orders`;
        }else{
          window.location = `${process.env.REACT_APP_API_HOST}/account?page=print`;
        }

      } else {
        alert('payment success');
      }
    } else {
      if (action.callback) {
        action.callback(new Error(transactiondata.msg));
      } else {
        alert('payment fail');
      }
    }
  }).catch(e => {
    if (action.callback) {
      action.callback(new Error('payment fail'));
    } else {
      alert('payment fail');
    }
    console.log('error: '+e);
    return e;
  });

  yield put({ type: 'SET_CREATED_ORDER', createOrder })
  yield put({ type: 'SET_IS_CREATING_ORDER', isLoading: false })

}*/

function* fetchShippingCountries(action) {
  var lang = action.lang;
  var shippingCountries = [];

  // TODO

   yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/print/get_delivery_options`, {
     method : "POST",
     headers : {
       "Access-Control-Allow-Origin" : "*",
       "Content-Type" : "application/json;charset=UTF-8",
     },
     body : JSON.stringify({
      "lang" : lang,
     })
   })
   .then(function(response) {
     return response.json();
   })
   .then(function(myJson) {
     shippingCountries = myJson.shippingCountries;
   }).catch(e => {
     console.log(e);
     return e;
   });

  yield put({ type: 'SET_SHIPPING_COUNTRY_LIST', shippingCountries });
}

function* detectUserCountryByIP() {
  var userCountry = null;

  // TODO
   yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/print/guess_user_country`, {
     method : "POST",
     headers : {
       "Access-Control-Allow-Origin" : "*",
       "Content-Type" : "application/json;charset=UTF-8",
     }
   })
   .then(function(response) {
     return response.json();
   })
   .then(function(myJson) {
     userCountry = myJson.country;
   }).catch(e => {
     console.log(e);
     return e;
   });

  yield put({ type: 'SET_DETECTED_USER_COUNTRY', country: userCountry });
  yield fetchQuote();
}
function* fetchPaymentCurrencies() {
  var paymentCurrencies = [];
  // TODO

   yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/print/get_currency_options`, {
     method : "POST",
     headers : {
       "Access-Control-Allow-Origin" : "*",
       "Content-Type" : "application/json;charset=UTF-8",
     }
   })
   .then(function(response) {
     return response.json();
   })
   .then(function(myJson) {
     paymentCurrencies = myJson.currencyOptions;
   }).catch(e => {
     console.log(e);
     return e;
   });
  yield put({ type: 'SET_PAYMENT_CURRENCY_LIST', paymentCurrencies });
}


/*
function saveJPDesign(data){
  var code = data.code;
  fetch(`http://localhost:8080/JoinPrint/ajax/saveJDDesign.asp`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
     "code" : code
    }),
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
     console.log(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });
}
*/

function addFolder(data){

  var param = {
    "folder_id" : 0,
    "folder_name" : data.folder_name,
    "code" : code
  };
  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/create-folder`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
    },
    body: JSON.stringify(param),
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
     console.log(myJson);
     data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function duplicateProject(data) {
  let code = data.code;
  let changes = data.changes;
  let variations = data.variations;
  let callback = data.callback;
  let param = {
    "changes" : JSON.stringify(changes),
    "variations" : JSON.stringify(variations),
  };
  fetch(`${process.env.REACT_APP_MARKETPLACE_API_HOST}/ajax/design/duplicate/${code}`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
    },
    body: JSON.stringify(param),
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
     //console.log(myJson);
     if (data.callback) {
      data.callback(myJson);
     }
  }).catch(e => {
    console.log(e);
    return e;
  });
}

function* watchProjectSaveAsync() {
  yield takeEvery('SAVE_PROJECT', saveProject);
  yield takeEvery('LOAD_FONTS', getFonts);
  yield takeEvery('SAVE_PRODUCT_OPTION', saveProductOption);
  //yield takeEvery('CREATE_PROJECT', createProject);
  //yield takeEvery('LOAD_PROJECT', loadProject);

  yield takeEvery('CHECK_USER_VERIFY_STATUS', checkUserVerifyStatus);
  yield takeEvery('CHECK_USER_SESSION', checkUserSession);
  //yield takeEvery('LOAD_PRINT_ONLY', loadPrintOnly);
  yield takeEvery('EXPORT_PROJECT', exportProject);
  yield takeEvery('EXPORT_PROJECT_V2', exportProjectV2);
  //yield takeEvery('UPLOAD_MINE', uploadMine);
  //yield takeEvery('GET_DESIGN_ELEMENT', getDesignElement);
  yield takeEvery('GET_RECENTLY_USED_IMAGES', getRecentlyUsedImages);
  yield takeEvery('SAVE_ELEMENT_ID', saveElementId);
  //yield takeEvery('DELETE_UPLOADED_IMG', deleteUploadedImg);
  yield takeEvery('GET_DESIGN_CATEGORY', getDesignCategory);
  yield takeEvery('SAVE_DESIGNER_TEMPLATE', saveDesignerTemplate);
  yield takeEvery('BIND_PROJECT', bindProject);
  yield takeEvery('SAVE_PREVIEW', savePreview);
  yield takeEvery('PROMPT_LOGIN', promptLogin);
  yield takeEvery('SET_PROJECT_SHARE', setProjectShare);
  yield takeEvery('SET_PROJECT_SHARE_TEAM', setProjectShareTeam);
  yield takeEvery('GET_PROJECT_SHARE', getProjectShare);
  //yield takeEvery('GET_IMAGE_BLOB' , getImageBlob);
  yield takeEvery('PROMPT_SLACK_LOGIN' , promptSlackLogin);
  yield takeEvery('GET_FOLDER_LIST' , getFolderList);
  yield takeEvery('SET_PROJECT_FOLDER' , setProjectFolder);
  yield takeEvery('CHECK_SOCIAL_MEDIA_TOKEN', checkSocialMediaToken);
  yield takeEvery('SLACK_UPLOAD_FILE', slackUploadFile);
  //yield takeEvery('GET_CUSTOM_FONTS', getCustomFonts);
  // yield takeEvery('LOAD_PRODUCT_LIST', fetchProductList);
  yield takeEvery('LOAD_SHIPPING_COUNTRY_LIST', fetchShippingCountries);
  yield takeEvery('LOAD_PAYMENT_CURRENCY_LIST', fetchPaymentCurrencies);
  yield takeEvery('DETECT_USER_COUNTRY_BY_IP', detectUserCountryByIP);
  //yield takeLatest('FETCH_QUOTE', fetchQuote);
  //yield takeEvery('CREATE_ORDER', createOrder);
  //yield takeEvery('VALIDATE_ADDRESS', validateAddress);
  yield takeEvery('SET_MY_FAVOURITE', setMyFavourite);
  //yield takeEvery('SEND_VERIFICATION_EMAIL', sendVerificationEmail);
  //yield takeEvery('SAVE_JP_DESIGN', saveJPDesign);
  yield takeEvery('ADD_FOLDER', addFolder);
  yield takeEvery('DUPLICATE_PROJECT', duplicateProject);
}


// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchProjectSaveAsync()
  ])
}
