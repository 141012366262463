import { makeid } from './general';

import { getBoundingBox } from "../math/frame";

export const getObjByHash = (hash, theObject) => {
    var result = null;
    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            result = getObjByHash(hash, theObject[i]);
            if (result) {
                break;
            }
        }
    } else {
        for(var prop in theObject) {
            if(prop === 'hash') {
                if(theObject[prop] === hash) {
                    return theObject;
                }
            }
            if(theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = getObjByHash(hash, theObject[prop]);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
};

export const assignHashToObj = (obj, reassign, hashReuseMap) => {
    if (!hashReuseMap) {
      hashReuseMap = {};
    }
    if (!obj) {
        return obj;
    } else if (Array.isArray(obj)) {
        return obj.map((item) => {
            return assignHashToObj(item, reassign, hashReuseMap);
        })
    } else {
        if (typeof obj === 'object') {
            if (reassign || !obj.hash) {
              let newHash = makeid(32)
              if (obj.hash) {
                hashReuseMap[obj.hash] = newHash;
              }
              obj.hash = newHash;
            }
        }
        for (var property in obj) {
            // eslint-disable-next-line
            if (obj.hasOwnProperty(property)) {
                if (obj[property] instanceof Object) {
                    obj[property] = assignHashToObj(obj[property], reassign, hashReuseMap);
                }
            }
        }
        return obj;
    }
};

export const applyChangeToElement = (elementHash, pages, changes) => {
    let obj = getObjByHash(elementHash, pages);
    if (obj)
    {
        for(var changeProp in changes) {
            obj[changeProp] = changes[changeProp];
        }
    } else {
        if(console.error)
            console.error('hash ' + elementHash + ' no exist on ', pages)
    }
    return obj;
};

export const applyChangesToElements = (pages, changes) => {
    for (let elementHash in changes) {
        let obj = getObjByHash(elementHash, pages);
        let change = changes[elementHash];
        for(var changeProp in change) {
            obj[changeProp] = change[changeProp];
        }
    }
    return pages;
};

export const getIdxByHash = (hash, theObject) => {
  var result = null;
  var page_id, el_id;
  if(theObject instanceof Array) {
      for(var i = 0; i < theObject.length; i++) {
          if (theObject[i].hash === hash) {
              page_id = i;
          } else {
              theObject[i].elements.map(function(el, idx) {
                  if (el.hash === hash) {
                      el_id = idx;
                      page_id = i;
                  }
              });
          }
          result = {
              pageIdx: page_id,
              elementIdx: el_id
          }
          if (result.pageIdx) {
              break;
          }
      }
  }
  return result;
};

export const getPageIndexByElementHash = (pages, elementHash) => {
  if (!pages) {
    return -1;
  }
  for (var index in pages) {
    let page = pages[index];
    if (getObjByHash(elementHash, page)) {
      return parseInt(index);
    }
  }
  return -1;
};

export const findGroupChildElements = (elements, groupHash) => {
  let targetChilds = [];
  let groupElement = getObjByHash(groupHash, elements);
  let groupChilds = groupElement.elements.map((hash) => getObjByHash(hash, elements));
  groupChilds.forEach((element) => {
    if (!element) {console.error(elements); return;}
    if (typeof element !== 'object') {console.error(elements); return;}
    if (element.type == 'group') {
      targetChilds = targetChilds.concat(findGroupChildElements(elements, element.hash));
    } else {
      targetChilds.push(element);
    }
  })
  return targetChilds;
}

export const findGroupElementByChildElement = (elements, childElement) => {
  return elements.find((element) => element.type == 'group' && element.elements.includes(childElement.hash));
}

export const resolveGroupElement = (elements) => {
  let groupElements = elements.filter((element) => element && element.type == 'group');
  let childCount = 0;
  let targetGroup = null;
  groupElements.forEach((groupElement) => {
    let childElementHashs = findGroupChildElements(elements, groupElement.hash).map((element) => element.hash);
    //let remainingElements = elements.filter((element) => element && element.type != 'group' && !childElementHashs.includes(element.hash));
    if (childElementHashs.length > childCount) {
      childCount = childElementHashs.length;
      targetGroup = groupElement;
    }
  })
  return targetGroup;
}

export const resolveElementsIndexInfo = (pageElements, targetElements) => {
  let indexs = targetElements.map((targetElement) => pageElements.findIndex((pageElement) => pageElement.hash == targetElement.hash))
  return {
    minIndex: Math.min(...indexs),
    maxIndex: Math.max(...indexs),
    indexs
  }
}

export const resizePage = (page, width, height) => {
  if (!page) return page;
  var imageWHRatio = page.width / page.height;
  var canvasWHRatio = width / height;
  var contentRatio = 1;
  var backgroundRatio = 1;
  const printBleedPadding = 0;//1.5/25.4*96; // 1.5mm

  if (imageWHRatio > canvasWHRatio) {
    contentRatio = width / page.width;
    backgroundRatio = (height + printBleedPadding * 2) / page.height;
  } else {
    contentRatio = height / page.height;
    backgroundRatio = (width + printBleedPadding * 2) / page.width;
  }

  var contentOffsetX = (width - page.width * contentRatio) / 2;
  var contentOffsetY = (height - page.height * contentRatio) / 2;
  //var backgroundOffsetX = (width - page.width * backgroundRatio) / 2;
  //var backgroundOffsetY = (height - page.height * backgroundRatio) / 2;

  page.width = width;
  page.height = height;
  page.elements.forEach((element) => {
    if (element.type == 'background') {
      let pageOffsetLeft = (width - element.width * backgroundRatio) / 2;
      let pageOffsetTop = (height - element.height * backgroundRatio) / 2;
      element.width = width;
      element.height = height;
      element.centerX = width / 2;
      element.centerY = height / 2;
      if (element.photoUrl) {
        element.imageWidth = element.imageWidth * backgroundRatio;
        element.imageHeight = element.imageHeight * backgroundRatio;
        element.imageOriginLeft = element.imageOriginLeft * backgroundRatio + pageOffsetLeft;
        element.imageOriginTop = element.imageOriginTop * backgroundRatio + pageOffsetTop;
      }
    } else {
      element.width = element.width * contentRatio;
      element.height = element.height * contentRatio;
      element.centerX = element.centerX * contentRatio + contentOffsetX;
      element.centerY = element.centerY * contentRatio + contentOffsetY;
      if (element.type == 'text') {
        element.textFontSize = element.textFontSize * contentRatio;
      } else if (element.type == 'photo' || element.type == 'graphic') {
        element.imageWidth = element.imageWidth * contentRatio;
        element.imageHeight = element.imageHeight * contentRatio;
        element.imageOriginLeft = element.imageOriginLeft * contentRatio;
        element.imageOriginTop = element.imageOriginTop * contentRatio;
      }
    }
  })
  return page;
}

export const elementsBoundingBox = (elements, clipWidth, clipHeight) => {
  let top = null;
  let left = null;
  let bottom = null;
  let right = null;
  elements.forEach((element) => {
    let boundingBox = getBoundingBox(element.centerX - element.width / 2, element.centerY - element.height / 2, element.width, element.height, element.rotation);
    top = (top === null) ? boundingBox.y : Math.min(top, boundingBox.y);
    left = (left === null) ? boundingBox.x : Math.min(left, boundingBox.x);
    bottom = (bottom === null) ? (boundingBox.y + boundingBox.height) : Math.max(bottom, boundingBox.y + boundingBox.height);
    right = (right === null) ? (boundingBox.x + boundingBox.width) : Math.max(right, boundingBox.x + boundingBox.width);

    if (clipWidth) {
      left = Math.max(0, left);
      right = Math.min(clipWidth, right);
    }
    if (clipHeight) {
      top = Math.max(0, top);
      bottom = Math.min(clipHeight, bottom);
    }
  });

  //let {centerX, centerY} = [(right + left) / 2, (bottom + top) / 2];
  return {
    centerX: (right + left) / 2,
    centerY: (bottom + top) / 2,
    width: right - left,
    height: bottom - top,
    top,
    left,
    bottom,
    right,
  }
}

export const adaptImageData = (targetImageData, applyImageData) => {
  const printBleedPadding = 1.5/25.4*300; // 1.5mm
  let data = JSON.parse(JSON.stringify(targetImageData));
  data.photoUrl = applyImageData.photoUrl;
  if (applyImageData.lowResImage) {
    data.lowResImage = applyImageData.lowResImage;
  }
  if (targetImageData.type == 'background') {
    let targetWHRatio = parseFloat(targetImageData.width + printBleedPadding * 2) / parseFloat(targetImageData.height + printBleedPadding * 2);
    let applyWHRatio = parseFloat(applyImageData.imageWidth) / parseFloat(applyImageData.imageHeight);

    if (applyWHRatio > targetWHRatio) {
      data.imageWidth =  parseFloat(targetImageData.height + printBleedPadding * 2) / parseFloat(applyImageData.imageHeight) * parseFloat(applyImageData.imageWidth);
      data.imageHeight = parseFloat(targetImageData.height + printBleedPadding * 2);
    } else {
      data.imageHeight = parseFloat(targetImageData.width + printBleedPadding * 2) / parseFloat(applyImageData.imageWidth) * parseFloat(applyImageData.imageHeight);
      data.imageWidth = parseFloat(targetImageData.width + printBleedPadding * 2);
    }
    data.imageOriginLeft = -(parseFloat(data.imageWidth) - parseFloat(targetImageData.width)) / 2;
    data.imageOriginTop = -(parseFloat(data.imageHeight) - parseFloat(targetImageData.height)) / 2;
  } else {
    let targetWHRatio = parseFloat(targetImageData.imageWidth) / parseFloat(targetImageData.imageHeight);
    let applyWHRatio = parseFloat(applyImageData.imageWidth) / parseFloat(applyImageData.imageHeight);

    if (applyWHRatio > targetWHRatio) {
      data.imageWidth =  parseFloat(targetImageData.imageHeight) / parseFloat(applyImageData.imageHeight) * parseFloat(applyImageData.imageWidth);
    } else {
      data.imageHeight = parseFloat(targetImageData.imageWidth) / parseFloat(applyImageData.imageWidth) * parseFloat(applyImageData.imageHeight);
    }
    data.imageOriginLeft -= (parseFloat(data.imageWidth) - parseFloat(targetImageData.imageWidth)) / 2;
    data.imageOriginTop -= (parseFloat(data.imageHeight) - parseFloat(targetImageData.imageHeight)) / 2;
  }

  return data;
}

export const isWithinMargin = (page, x, y) => {
  const margin = Math.min(page.width, page.height) * 0.1;
  return (x <= margin || y <= margin || x > (page.width - margin) || y > (page.height - margin)) && x >= 0 && y >= 0 && x <= page.width && y <= page.height;
}

