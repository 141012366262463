export const makeid = (length) => {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export const nl2br = (str, is_xhtml) => {
      if (typeof str === 'undefined' || str === null) {
          return '';
      }
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag);
  }

export const isInViewport = (element, options) => {
  let {top, bottom, left, right} = element.getBoundingClientRect()

  let settings = Object.assign({
    tolerance: 0,
    viewport: window
  }, options)
  let isVisibleFlag = false
  let $viewport = document.querySelector(settings.viewport)

  if (!$viewport.length) {
    console.warn('isInViewport: The viewport selector you have provided matches no element on page.')
    console.warn('isInViewport: Defaulting to viewport as window')
    $viewport = window
  }

  const $viewportHeight = $viewport.height
  let $viewportWidth = $viewport.width
  const typeofViewport = $viewport.toString()

  // if the viewport is other than window recalculate the top,
  // bottom,left and right wrt the new viewport
  // the [object DOMWindow] check is for window object type in PhantomJS
  if ($viewport[0] !== window && typeofViewport !== '[object Window]' && typeofViewport !== '[object DOMWindow]') {
    // use getBoundingClientRect() instead of $.Offset()
    // since the original top/bottom positions are calculated relative to browser viewport and not document
    const viewportRect = $viewport.getBoundingClientRect()

    // recalculate these relative to viewport
    top = top - viewportRect.top
    bottom = bottom - viewportRect.top
    left = left - viewportRect.left
    right = right - viewportRect.left

    // get the scrollbar width from cache or calculate it
    //isInViewport.scrollBarWidth = isInViewport.scrollBarWidth || getScrollbarWidth($viewport)

    // remove the width of the scrollbar from the viewport width
    //$viewportWidth -= isInViewport.scrollBarWidth
  }

  // handle falsy, non-number and non-integer tolerance value
  // same as checking using isNaN and then setting to 0
  // bitwise operators deserve some love too you know
  settings.tolerance = ~~Math.round(parseFloat(settings.tolerance))

  if (settings.tolerance < 0) {
    settings.tolerance = $viewportHeight + settings.tolerance // viewport height - tol
  }

  // the element is NOT in viewport iff it is completely out of
  // viewport laterally or if it is completely out of the tolerance
  // region. Therefore, if it is partially in view then it is considered
  // to be in the viewport and hence true is returned. Because we have adjusted
  // the left/right positions relative to the viewport, we should check the
  // element's right against the viewport's 0 (left side), and the element's
  // left against the viewport's width to see if it is outside of the viewport.

  if (right <= 0 || left >= $viewportWidth) {
    return isVisibleFlag
  }

  // if the element is bound to some tolerance
  isVisibleFlag = settings.tolerance ? top <= settings.tolerance && bottom >= settings.tolerance : bottom > 0 && top <= $viewportHeight

  return isVisibleFlag
}

export const base64ToBlob = (dataURI) =>
{
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = window.atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;

}
